import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, zip } from 'rxjs';
import { filter, map, skipWhile, take, tap } from 'rxjs/operators';
import { ProductDTO } from '../model/product-dto';
import { RepresentativeDTO } from '../model/representative-dto';
import { SubPageDTO } from '../model/sub-page-dto';
import { MetaService } from './meta.service';

@Injectable ( {
    providedIn: 'root'
} )
export class DataService {



    readonly products: BehaviorSubject<ProductDTO[]>               = new BehaviorSubject<ProductDTO[]> ( null );
    readonly subpages: BehaviorSubject<SubPageDTO[]>               = new BehaviorSubject<SubPageDTO[]> ( null );
    readonly representatives: BehaviorSubject<RepresentativeDTO[]> = new BehaviorSubject<RepresentativeDTO[]> ( null );
    readonly metaData: BehaviorSubject<any[]>                      = new BehaviorSubject<any[]> ( null );

    constructor( private http: HttpClient,
                 private router: Router,
                 @Inject ( PLATFORM_ID ) private pltID: any,
                 private meta: MetaService,
                 private zone: NgZone
    ) {

    }

    getProductCategory( cat ): Observable<ProductDTO[]> {
        return this.products
                   .pipe (
                       map ( value => value.filter ( val => val.category === cat ) )
                   );
    }

    init() {
        return new Promise ( (resolve => {
                zip (
                    this.http.get<any> ( '/assets/data/products.json' ),
                    this.http.get<any> ( '/assets/data/subpage.json' ),
                    this.http.get<any> ( '/assets/data/representatives.json' ),
                    this.http.get<any> ( '/assets/data/meta.json' )
                )
                    .pipe (
                        take ( 1 )
                    )
                    .subscribe ( ( values ) => {
                            this.products.next ( values.shift ().data );
                            this.subpages.next ( values.shift ().data );
                            this.representatives.next ( values.shift ().data );
                            this.metaData.next ( values.shift ().data );
                            resolve ();
                        },
                        error => console.error ( error ) );

                combineLatest ( [
                    this.router.events,
                    this.metaData
                ] )
                    .pipe (
                        skipWhile ( value => !value[ 0 ] || !value[ 1 ] ),
                        filter ( value => value[ 0 ] instanceof NavigationEnd ),
                        tap ( val => console.warn ( val[ 0 ].toString () ) ),
                        map ( values => values[ 1 ].filter ( val => val.slug === (values[ 0 ] as NavigationEnd).url )
                                                   .shift () )
                    )
                    .subscribe ( ( value ) => {
                        this.zone.runOutsideAngular ( () => {
                            if ( value ) {
                                this.meta.setViewMeta (
                                    value.title, value.description, value.image
                                );
                            } else {
                                this.meta.setDefaultMeta ();
                            }
                        } );
                    } );

            }

        ) );

    }
}
