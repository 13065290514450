import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';

@Injectable ( {
    providedIn: 'root'
} )
export class MetaService {

    private canonical: HTMLLinkElement;

    constructor( private meta: Meta,
                 private title: Title,
                 @Inject ( PLATFORM_ID ) private pltID: any,
                 @Inject ( DOCUMENT ) private doc: Document
    ) {

        this.canonical = this.doc.createElement ( 'link' );
        this.canonical.setAttribute ( 'rel', 'canonical' );
        this.doc.head.appendChild ( this.canonical );
    }

    // tslint:disable-next-line:variable-name
    private _defaultSchema: any;

    get defaultSchema(): any {
        return this._defaultSchema;
    }

    set defaultSchema( value: any ) {
        this._defaultSchema = value;
        this.schema         = this._defaultSchema;
    }

    // tslint:disable-next-line:variable-name
    private _schema: any = {};

    get schema(): any {
        return this._schema;
    }

    set schema( value: any ) {
        this._schema = value;
    }

    setDefaultMeta() {
        const defaultTitle = this.getMeta ( 'title', 'Sossna Spinnerets' );
        const defaultDesc  = this.getMeta ( 'description', 'Sossna Spinnerets' );

        const defaultKey = this.getMeta ( 'keywords', '' );

        const defaultImage = this.getMeta ( 'image', '/assets/images/sossna-lead-image.jpg' );

        this.update ( [defaultTitle,
                       defaultDesc,
                       defaultImage,
                       defaultKey
        ], true, true );

        this.title.setTitle ( 'Sossna Spinnerets' );

        this.schema = this._defaultSchema;

    }

    setViewMeta( title: string, desc: string, img?: string, keywords?: string ) {
        this.update ( [
            this.getMeta ( 'title', title ),
            this.getMeta ( 'description', desc ),
            this.getMeta ( 'image', img ),
            this.getMeta ( 'keywords', keywords )
        ], true, true );
        this.title.setTitle ( title );

    }

    setFavicon( url ) {
        this.doc.head.querySelectorAll ( 'link' )
            .forEach ( ( value ) => {
                if ( value.rel === 'icon' ) {
                    value.type = 'image/*';
                    value.href = url;
                }
            } );

    }

    setNotFound( found ) {
        this.setDefaultMeta ();
    }

    private toFacebook( t: MetaDefinition ): MetaDefinition {
        const f: MetaDefinition = JSON.parse ( JSON.stringify ( t ) );
        f.property              = 'og:' + f.name;
        delete f.name;
        return f;
    }

    private toTwittter( t: MetaDefinition ): MetaDefinition {
        const f: MetaDefinition = JSON.parse ( JSON.stringify ( t ) );
        f.property              = 'twitter:' + f.name;
        delete f.name;
        return f;
    }

    private getMeta( name: string, value: string ): MetaDefinition {
        if ( !value ) {
            return null;
        }
        return {
            name,
            content: value
        };
    }

    private update( tag: MetaDefinition | MetaDefinition[], facebook?: boolean, twitter?: boolean ) {
        const u = ( t: MetaDefinition ) => {
            if ( t ) {
                this.meta.updateTag ( t, 'name=' + t.name );

                if ( facebook ) {

                    this.meta.updateTag ( this.toFacebook ( t ), 'property=\'' + this.toFacebook ( t ).property + '\'' );

                }
                if ( twitter ) {

                    this.meta.updateTag ( this.toTwittter ( t ), 'property=\'' + this.toTwittter ( t ).property + '\'' );

                }

            }
        };
        if ( tag.hasOwnProperty ( 'length' ) ) {
            for ( const t of (tag as MetaDefinition[]) ) {
                u ( t );
            }
        } else {
            u ( tag as MetaDefinition );
        }

        this.setCanonical ();
    }

    private setCanonical() {
        this.doc.querySelectorAll ( 'link[rel="canonical"]' )
            .forEach ( item => {
                item.remove ();
            } );
        this.canonical = this.doc.createElement ( 'link' );
        this.canonical.setAttribute ( 'rel', 'canonical' );
        this.doc.head.appendChild ( this.canonical );
        let url: string         = this.doc.documentURI;
        const indexOfQM: number = url.indexOf ( '?' );
        if ( indexOfQM > - 1 ) {
            url = url.slice ( 0, indexOfQM );
        }
        url = (isPlatformServer ( this.pltID ) ? environment.host : '') +
            url.replace ( /(\s|\/)*$/, '' ) +
            '/';

        this.canonical.setAttribute ( 'href', url );

        /*
        for ( const img of Array.from ( window.document.querySelectorAll ( 'img' ) ) ) {
            if ( !img.hasAttribute ( 'alt' ) ) {
                this.imgNum ++;
                img.setAttribute ( 'alt', environment.defaultALT );
            }
        }

         */
    }
}
