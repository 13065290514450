import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DeferDirective } from './defer.directive';

@NgModule ( {
    declarations: [
        DeferDirective
    ],
    exports     : [
        DeferDirective
    ],
    imports     : [
        CommonModule
    ]
} )
export class DeferModule {
}
