import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path        : '',
        loadChildren: () => import(/* webpackPrefetch: true */'./home/home.module').then ( m => m.HomeModule ),
        pathMatch   : 'full'
    },

    {
        path        : 'products',
        loadChildren: () => import(/* webpackPrefetch: true */'./products/products.module').then ( m => m.ProductsModule )
    },
    {
        path        : 'representatives',
        loadChildren: () =>
            import(/* webpackPrefetch: true */'./representatives/representatives.module').then ( m => m.RepresentativesModule )
    },
    {
        path        : ':pageid',
        loadChildren: () => import(/* webpackPrefetch: true */'./sub-page/sub-page.module').then ( m => m.SubPageModule )
    }
];

@NgModule ( {
    imports: [
        RouterModule.forRoot ( routes, {
            initialNavigation: 'enabled',
            scrollPositionRestoration: 'top'
        } )
    ],
    exports: [RouterModule]
} )
export class AppRoutingModule {
}
