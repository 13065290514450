import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DataService } from './control/data.service';
import { DeferModule } from './control/defer/defer.module';
import { LoadInterceptor } from './control/load.interceptor';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

@NgModule ( {
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule.withServerTransition({appId: 'serverApp'}),
        BrowserTransferStateModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        HttpClientModule,
        MatIconModule,
        DeferModule,
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
        FormsModule

    ],
    providers   : [
        // { provide: ErrorHandler, useClass: SentryErrorHandler }
        {
            provide : HTTP_INTERCEPTORS,
            useClass: LoadInterceptor,
            multi   : true
        },
        {
            provide   : APP_INITIALIZER,
            deps      : [DataService
            ],
            multi     : true,
            useFactory: initFactory
        }
    ],
    bootstrap   : [AppComponent]
} )
export class AppModule {
}

export function initFactory( data: DataService ) {
    return (): Promise<any> => data.init ();
}
