import { isPlatformServer } from '@angular/common';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable ()
export class LoadInterceptor implements HttpInterceptor {

    constructor( @Inject ( PLATFORM_ID ) private pltID: any,
                 private transfer: TransferState
    ) {
    }

    intercept( request: HttpRequest<unknown>, next: HttpHandler ): Observable<HttpEvent<unknown>> {
        const rep = 'http://localhost:4000/';
        const KEY = makeStateKey ( request.url );

        if ( this.transfer.hasKey ( KEY ) ) {
            return of (
                new HttpResponse ( { body: this.transfer.get ( KEY, null ), status: 200 } )
            );
        }
        let r: HttpRequest<unknown>;
        if ( isPlatformServer ( this.pltID ) && (request.url.startsWith ( './' ) || request.url.startsWith ( '/' )) ) {
            r = request.clone ( {
                url: rep + request.url.slice ( request.url.startsWith ( '/' ) ? 1 : 2 )
            } );
        } else {
            r = request;
        }
        return next.handle ( r )
                   .pipe (
                       tap ( val => {
                           if ( (val as any).body ) {
                               this.transfer.set ( KEY, (val as any).body );
                           }
                       } )
                   );
    }
}
