import {Component, HostBinding, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { DataService } from './control/data.service';
import {HttpClient} from '@angular/common/http';

@Component ( {
    selector   : 'nt-sossna-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
} )
export class AppComponent implements OnInit {

    title                                              = 'sossna-homepage';
    @HostBinding ( 'class.modal-open' ) openMobileMenu = false;

    mailSend: boolean;
    private startTime: number;

    menu = [
        {
            label: 'Home',
            link : '/'
        },
        {
            label   : 'Company Profile',
            link    : null,
            children: of ( [
                {
                    label: 'Company Profile',
                    slug : 'company-profile'
                },
                {
                    label: 'Corporate Image',
                    slug : 'corporate-image'
                }
            ] )
        },
        {
            label   : 'Products',
            link    : null,
            categories: [
                {
                    label: 'Spinnerets',
                    data : this.data.getProductCategory(1)
                },
                {
                    label: 'Spinpacks',
                    data : this.data.getProductCategory(2)
                },
                {
                    label: 'Processing Components',
                    data : this.data.getProductCategory(3)
                }
            ]
        },
        {
            label: 'Service',
            link : '/service'
        },
        {
            label: 'Downloads',
            link : '/downloads'
        },
        {
            label   : 'Contact',
            link    : null,
            children: of (
                [
                    {
                        label: 'Contact / Imprint',
                        slug : 'contact-imprint'
                    },
                    {
                        label: 'Representatives',
                        slug : 'representatives'
                    }
                ]
            )
        }

    ];

    constructor( private router: Router,
                 private data: DataService,
                 private $http: HttpClient
    ) {

        this.router.events
            .subscribe ( ( event ) => {
                this.openMobileMenu = false;
            } );
    }

    ngOnInit() {
        this.startTime = Date.now ();
    }

    postMyForm( value: any ) {
        if ( Date.now () - this.startTime > 5000 ) {
            this.$http.post ( '/scripts/mail.php', value )
                .subscribe (
                    next => this.mailSend = true,
                    error => this.mailSend = false
                );
        }
    }
}
