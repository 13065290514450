<nav class="main-navigation">
    <a class="main-logo" routerLink="/">
        <img alt="Sossna Logo" src="./assets/images/sossna-logo.png">
    </a>
    <div class="spacer"></div>
    <div [class.mobile-menu-open]="this.openMobileMenu" class="navigation-links">
        <div *ngFor="let l of this.menu" [class.sub]="!!l.children ||!!l.categories" class="menuitem">

            <a [routerLink]="l.link">{{l.label}}
                <i class="arrow-drop-down"></i></a>

            <div *ngIf="l.children | async as submenu" class="submenu">
                <a *ngFor="let s of submenu" [routerLink]="s.slug">{{s.label}}</a>
            </div>


            <div *ngIf="l.categories" class="submenu">
                <ng-container *ngFor="let cat of l.categories">
                    <a *ngIf="(cat.data | async)[0] as first" [routerLink]="first.slug" class="catlink">{{cat.label}}</a>
                    <div class="category">
                        <h3>{{cat.label}}</h3>
                        <div>
                            <a *ngFor="let s of cat.data |async" [routerLink]="s.slug">{{s.label}}</a>
                        </div>
                    </div>
                </ng-container>

            </div>


        </div>
    </div>
    <div class="spacer"></div>

    <a class="branding-logo">
        <img alt="FXS Logo" src="./assets/images/sossna-fxs-logo.png">
    </a>

    <a (click)="this.openMobileMenu = !this.openMobileMenu" class="mobile-menu">
        <img *ngIf="!openMobileMenu" alt="Open Menu" height="21px" ntDefer="./assets/icons/icon-open-mobile-menu.png"
             width="29px">
        <img *ngIf="openMobileMenu" alt="Close Menu" height="25px" ntDefer="./assets/icons/icon-close-mobile-menu.png"
             width="25px">
    </a>
</nav>

<router-outlet></router-outlet>

<footer [class.mobile-menu-open]="this.openMobileMenu">
    <div class="get-in-touch-container" ntDefer>
        <p>Get in touch and let us know how we can help.</p>
    </div>

    <div class="dark-footer-container">
        <div class="dark-footer-background-container dark-footer-bg-image-container" ntDefer></div>
        <div class="dark-footer-background-container dark-footer-gradient-container"></div>

        <div class="dark-footer-content-container">
            <div class="footer-description-container footer-column">
                <h3>Trademarks</h3>
                <div class="trademark-logos">
                    <img alt="Sossna FXS Logo" ntDefer="./assets/images/sossna-fxs-logo-weiss.png" class="fxs-logo">
                    <img alt="Sossna Spinnerets Logo" ntDefer="./assets/logos/sossna_spinnerets.png">
                    <img alt="Sossna Diamond Edge Logo" ntDefer="./assets/logos/sossna_diamond_edge.png">
                    <img alt="Sossna Diamond Edge Eco Logo" ntDefer="./assets/logos/sossna-diamond-edge-eco-logo.png">
                </div>
                <p></p>
            </div>

            <div class="footer-contact-form-container footer-column" id="get-in-contact">
                <div class="email-message success-message" *ngIf="mailSend">
                    <p>Your message was sent successfully. We will be in contact shortly.</p>
                </div>
                <div class="email-message error-message" *ngIf="mailSend === false">
                    <p>We are sorry, but there was an issue sending this mail. Please check your data or try again at a later time.</p>
                </div>

                <form #myForm="ngForm" (submit)="postMyForm( myForm.value )">
                    <label for="name">Name</label>
                    <input autocomplete="name" name="FullName" ngModel="" placeholder="Full Name" required="required" type="text" id="name">

                    <label for="company">Company</label>
                    <input autocomplete="organization" name="Firma" ngModel="" placeholder="Company" type="text" id="company" required="required">

                    <label for="email">Email</label>
                    <input autocomplete="email" name="Email" ngModel="" placeholder="E-Mail" required="required" type="email" id="email">

                    <label for="message">Message</label>
                    <textarea minlength="3" name="Nachricht" ngModel="" placeholder="Your Message" required="required" id="message"></textarea>

                    <label for="dsgvo" class="dsgvo-label">
                        <input id="dsgvo" name="dsgvo" ngModel="" required="required" type="checkbox">
                        <span>
                            By using this form you agree to our
                            <a routerLink="/privacy">Privacy Policy</a>
                        </span>
                        <div class="dsgvo-checkbox"></div>
                    </label>

                    <button class="red-hover-button" type="submit" [disabled]="myForm.invalid" aria-label="submit">
                        <span>Send Message</span>
                    </button>

                    <div *ngIf="myForm.invalid && myForm.dirty" style="color: red; font-size: smaller; margin-top: 10px">
                        Please fill in all fields accordingly.
                    </div>
                </form>

                <p class="copyright-notice">Copyright 2020 All Rights Reserved</p>
            </div>

            <div class="footer-contact-details-container footer-column">
                <div class="contact-details-content-container">
                    <div class="contact-detail">
                        <img alt="Phone Number" ntDefer="./assets/icons/icon-phone-weiss.png">
                        <p>
                            Phone: +49 2362 99371 0<br>
                            Fax: +49 2362 99371 20
                        </p>
                    </div>

                    <div class="contact-detail">
                        <img alt="Email" ntDefer="./assets/icons/icon-email-weiss.png">
                        <p>
                            sossna@sossna.de
                        </p>
                    </div>

                    <div class="contact-detail">
                        <img alt="Map" ntDefer="./assets/icons/icon-map-weiss.png">
                        <p>
                            Sossna GmbH<br>
                            Fürst-Leopold-Allee 96<br>
                            DE-46284 Dorsten<br>
                            Germany
                        </p>
                    </div>

                    <div class="social-container">
                        <a href="https://www.facebook.com/Sossna-GmbH-189245667812244/" target="_blank" rel="noopener">
                            <img src="./assets/icons/icon-facebook.png" alt="Facebook">
                        </a>

                        <a href="https://de.linkedin.com/company/sossna-gmbh" target="_blank" rel="noopener">
                            <img src="./assets/icons/icon-linkedin.png" alt="LinkedIn">
                        </a>
                    </div>

                    <div class="legal-links">
                        <a routerLink="privacy">Privacy policy</a>
                        |
                        <a routerLink="contact-imprint">Imprint</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
