import { Directive, ElementRef, Input } from '@angular/core';
import { ImageDeferService } from './image-defer.service';

@Directive ( {
    selector: '[ntDefer]'
} )
export class DeferDirective {

    @Input () ntDefer;

    element;

    constructor( private el: ElementRef,
                 private defer: ImageDeferService
    ) {
        this.element = this.el.nativeElement;
        this.element.classList.add ( 'deferred' );
        this.defer.add ( this );
    }

}
