import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DeferDirective } from './defer.directive';

@Injectable ( {
    providedIn: 'root'
} )
export class ImageDeferService {

    readonly intersect: IntersectionObserver;

    element: Map<Element, DeferDirective> = new Map<Element, DeferDirective> ();

    constructor(
        @Inject ( PLATFORM_ID ) private pltID: any
    ) {
        if ( !isPlatformServer ( this.pltID ) ) {
            this.intersect = new IntersectionObserver ( (entries => {
                for ( const e of entries ) {
                    if ( e.target && e.isIntersecting ) {
                        const d = this.element.get ( e.target );
                        if ( d.ntDefer ) {
                            d.element.src = d.ntDefer;
                        } else {
                            d.element.classList.remove ( 'deferred' );
                        }
                    }
                }
            }) );
        }

    }

    add( d: DeferDirective ) {
        if ( isPlatformServer ( this.pltID ) || !this.intersect ) {
            //d.element.src = d.ntDefer;
            d.element.classList.remove ( 'deferred' );
        } else {
            this.element.set ( d.element, d );
            this.intersect.observe ( d.element );

        }
    }

    remove( d: DeferDirective ) {
        this.element.delete ( d.element );
        this.intersect.unobserve ( d.element );
    }
}

